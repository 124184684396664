import React from 'react'

import './social-media-box.css'


export default function SocialMediaBox () {

  return (
    <div className="social-media-box">
      <h3>Find Us On Social Media</h3>
      <p>We're just a group of car enthusiasts.</p>

      <div className="social-media-container">
        <a className="social-media-link social-btn" href="https://www.facebook.com/allstocksociety/">Facebook</a>
        <a className="social-media-link social-btn" href="https://instagram.com/allstockwrx">Instagram</a>
        <a className="social-media-link social-btn" href="https://twitter.com/allstockwrx">Twitter</a>
      </div>
    </div>
  )
}