import React from 'react'
import Header from './header'
import Footer from './footer'
import './layout.css'

export default function Layout ({ children }) {
  return (
    <>
      <Header />

      <main className="main-container">{children}</main>

      <Footer />
    </>
  )
}