import React from 'react'
import Menu from './menu'
import './header.css'

export default function Header() {
  return (

    <header className="header">
      <div className="header-title-box">
        <h1 className="header-title">ALL STOCK SOCIETY</h1>
      </div>
      
      <Menu />
    </header>

    )
}