import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import SocialMediaBox from '../components/social-media-box'
import ContactBox from '../components/contact-box'
import '../styles/global.css'



export default function About() {

  return (

    <Layout>

      <SEO />

      <ContactBox />

      <SocialMediaBox />

    </Layout>
  )
}