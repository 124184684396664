import React from 'react'
import { Link } from 'gatsby'
import './menu.css'

export default function Menu () {
  return (
    <nav>
      <div className="menu-link-box">
        <Link className="menu-link" to="/">HOME</Link>
        <Link className="menu-link" to="/about">ABOUT</Link>
        <Link className="menu-link" to="/contact">CONTACT</Link>

      </div>
    </nav>
  )
}