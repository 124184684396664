import React from 'react'
import Menu from './menu'
import './footer.css'

export default function Footer() {

  return(
    <footer className="footer">
      
      <Menu />
      <div className="copy-box">
        <p>&copy; 2020 All Stock Society. Powered by <a className="powered-by-link" href= "https://dfw-studio.com">DFW Studio - Custom Web Design</a></p>
      </div>
    </footer>
  )
}